<template>
	<div class="dragdrop-area" v-on:click="onClick">
		<input type="file" ref="file_input" style="display:none" :accept="this.accept" multiple>
		<slot></slot>
	</div>
</template>

<script>
export default {
  name: 'DragDropArea',
  props: {
		accept: {
			type: String,
			default: '*/*',
		}
	},
	data: function() {
		return {
		};
	},
	methods: {
		onClick: function() {
			this.clear();
			this.$refs.file_input.click();
		},
		onFileSelected: function() {
			let files = this.$refs.file_input.files;
			this.$emit('before-files-changed', files);
			for (let file of files) {
				this.$emit('file-added', file);
			}
			this.$emit('files-changed', files);
		},
		clear: function() {
			this.$refs.file_input.value = '';
		},
	},
	mounted: function () {
		this.$el.ondragover  = (e) => {
			e.preventDefault();
		};
		this.$el.ondragenter = (e) => {
			this.$el.classList.add('dragover');
			e.preventDefault();
		};
		this.$el.ondragleave = (e) => {
			this.$el.classList.remove('dragover');
		};
		
		this.$el.ondrop = (e) => {
			this.$el.classList.remove('dragover');
			this.$refs.file_input.files = e.dataTransfer.files;
			e.preventDefault();
			this.onFileSelected();
		};

		this.$refs.file_input.onchange = () => {
			this.onFileSelected();
		};
	},
}
</script>

<style>
</style>
