<template>
  <div class="mx-auto max-w-screen-lg md:p-5 text-gray-600 dark:text-gray-300">

	<!-- Description -->
    <div class="<md:text-center text-gray-600 dark:(text-gray-200) py-3 select-none">
      <span class="font-playful text-lg <md:px-5 bg-white dark:(bg-gray-700)">Ein PDF hochladen, Seitennummern hinzufügen, Abstand und Schriftart wählen und Speichern.</span>
    </div>

    <!-- Images List -->
	<DragDropArea ref="file-upload" accept="application/pdf" v-on:file-added="onFileUploaded" v-on:before-files-changed="onBeforeFilesChanged" v-on:files-changed="onAfterFilesChanged" />
    <div class="bg-white dark:(bg-gray-700) rounded mx-3 md:mx-5 p-2">
		<!-- Files -->
		<div>
			<div v-if="file != null" class="my-1 h-16 md:h-22 bg-white ring-1 ring-gray-200 dark:(bg-gray-700 ring-gray-800) rounded flex justify-start select-none group">
				<div v-if="file.preview" class="rounded-l h-full w-16 min-w-16 md:(w-22 min-w-22) overflow-hidden">
					<img class="w-full h-full object-cover" :src="file.preview"></img>
				</div>
				<div v-else class="w-18 md:(w-22 min-w-22) h-full relative text-gray-500 flex justify-center items-center">
					<div class="flex flex-col gap-y-1 items-center">
						<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-file-earmark-pdf-fill text-xl" viewBox="0 0 16 16"><path d="M5.523 12.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.148 21.148 0 0 0 .5-1.05 12.045 12.045 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.881 3.881 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 6.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z"/><path fill-rule="evenodd" d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm5.5 1.5v2a1 1 0 0 0 1 1h2l-3-3zM4.165 13.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.651 11.651 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.697 19.697 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z"/></svg>
						<span class="text-xs font-playful leading-none">{{ file.pages }} Seite{{ file.pages === 1 ? '' : 'n' }}</span>
					</div>
				</div>
				<div class="w-full h-full flex justify-between items-center pl-3 truncate">
					<div class="flex flex-col truncate">
						<span class="font-playful font-semibold text-gray-400 truncate">{{ file.file.name }}</span>
						<p class="font-playful text-sm text-gray-300">{{ humanFileSize(file.file.size) }}</p>
					</div>
					<div v-on:click="onRemoveFile" class="h-full w-12 min-w-12 flex justify-center items-center text-gray-200 dark:(text-gray-800) hover:(text-red-300) cursor-pointer transition transition-color">
						<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16"> <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/> <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/></svg>
					</div>
				</div>
			</div>

			<div v-if="file != null && file.pages != null" class="mx-5">
				<div class="grid grid-cols-1 md:grid-cols-2 justify-between">
					<!-- Page Number Position -->
					<div>
						<h3 class="text-lg font-playful font-semibold mt-5">Seitennummer Position</h3>
						<div class="mx-auto grid grid-cols-2 gap-1 text-2xl w-fit" style="width: fit-content;">
							<button v-on:click="pagenum_pos = 'top,left'"     class="w-min focus:outline-none" :class="{'opacity-20': pagenum_pos !== 'top,left'}">
								<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" class="bi bi-arrow-up-left-square-fill" viewBox="0 0 16 16"> <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm8.096 10.803L6 6.707v2.768a.5.5 0 0 1-1 0V5.5a.5.5 0 0 1 .5-.5h3.975a.5.5 0 1 1 0 1H6.707l4.096 4.096a.5.5 0 1 1-.707.707z"/> </svg>
							</button>
							<button v-on:click="pagenum_pos = 'top,right'"    class="w-min focus:outline-none" :class="{'opacity-20': pagenum_pos !== 'top,right'}">
								<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" class="bi bi-arrow-up-right-square-fill" viewBox="0 0 16 16"> <path d="M14 0a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12zM5.904 10.803 10 6.707v2.768a.5.5 0 0 0 1 0V5.5a.5.5 0 0 0-.5-.5H6.525a.5.5 0 1 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 .707.707z"/> </svg>
							</button>
							<button v-on:click="pagenum_pos = 'bottom,left'"  class="w-min focus:outline-none" :class="{'opacity-20': pagenum_pos !== 'bottom,left'}">
								<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" class="bi bi-arrow-down-left-square-fill" viewBox="0 0 16 16"> <path d="M2 16a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2zm8.096-10.803L6 9.293V6.525a.5.5 0 0 0-1 0V10.5a.5.5 0 0 0 .5.5h3.975a.5.5 0 0 0 0-1H6.707l4.096-4.096a.5.5 0 1 0-.707-.707z"/> </svg>
							</button>
							<button v-on:click="pagenum_pos = 'bottom,right'" class="w-min focus:outline-none" :class="{'opacity-20': pagenum_pos !== 'bottom,right'}">
								<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" class="bi bi-arrow-down-right-square-fill" viewBox="0 0 16 16"> <path d="M14 16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12zM5.904 5.197 10 9.293V6.525a.5.5 0 0 1 1 0V10.5a.5.5 0 0 1-.5.5H6.525a.5.5 0 0 1 0-1h2.768L5.197 5.904a.5.5 0 0 1 .707-.707z"/> </svg>
							</button>
						</div>
					</div>
					
					<!-- Page Number Margin -->
					<div>
						<h3 class="text-lg font-playful font-semibold mt-5">Seitennummer Abstand</h3>
						<div class="flex flex-col gap-y-1 font-playful">
							<label class="flex justify-between gap-x-1">
								Horizontal
								<span class="whitespace-nowrap">
									<input type="number" ref="margin_x" class="w-22 text-right dark:(bg-gray-600) focus:outline-none rounded px-1" value="24"/>
									<span class="text-sm opacity-50 ml-0.5">px</span>
								</span>
							</label>
							<label class="flex justify-between gap-x-1">
								Vertikal
								<span class="whitespace-nowrap">
									<input type="number" ref="margin_y" class="w-22 text-right dark:(bg-gray-600) focus:outline-none rounded px-1" value="24"/>
									<span class="text-sm opacity-50 ml-0.5">px</span>
								</span>
							</label>
						</div>
					</div>
				</div>

				<!-- Schriftart -->
				<div class="grid grid-cols-2">
					<div>
						<h3 class="text-lg font-playful font-semibold mt-5">Schriftart</h3>
						<select ref="fontfamily" class="px-1.5 h-10 dark:focus:(outline-none) dark:bg-gray-600 rounded font-playful">
							<option v-for="font in availableFonts" :value="font" :selected="font === 'TimesRoman'">{{ font.replace('Oblique', 'Italic') }}</option>
						</select>
					</div>
					<div>
						<h3 class="text-lg font-playful font-semibold mt-5">Schriftgröße & -farbe</h3>
						<div class="flex gap-x-1 h-10">
							<input type="number" ref="fontsize" value="24" class="p-2 w-24 dark:focus:(outline-none) dark:bg-gray-600 rounded font-playful" />
							<input type="color" ref="fontcolor" value="#000000" class="px-1 w-24 h-full dark:focus:(outline-none) dark:bg-gray-600 rounded" />
						</div>
					</div>
				</div>

				<!-- Seitennummern -->
				<h3 class="text-lg font-playful font-semibold mt-5">Seiten-Nummerierung</h3>
				<ul class="text-sm mx-1 sm:mx-3">
					<li v-for="index in file.pages" :key="index" class="flex justify-between">
						<span class="font-mono">Seite {{ String(index).padStart(2, '&nbsp;') }}</span>
						<span class="flex-grow mx-2 my-1 border-dotted border-b-2 border-gray-400 dark:(border-gray-800)"></span>
						<input type="text" :value="index" class="pagenum_index font-mono focus:(outline-none) dark:focus:bg-gray-600 w-8 text-right dark:bg-gray-700"/>
					</li>
				</ul>
			</div>
		</div>

		<!-- Add file -->
		<div v-if="file === null" v-on:click="onUploadClick" class="relative overflow-hidden border border-blue-50 bg-gray-50 hover:(bg-gray-100) dark:(bg-gray-600 border-gray-800) dark:hover:bg-gray-600 rounded px-2 py-2 mt-2 flex justify-between select-none cursor-pointer group" :class="{'cursor-not-allowed': isGeneratingPdf}">
			<span class="relative z-10 font-playful text-gray-400 group-hover:(underline)">Dateien <span v-if="file === null">auswählen</span><span v-else>hinzufügen</span>...</span>
			<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="relative z-10 bi bi-upload text-gray-300 mr-1" viewBox="0 0 16 16"><path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/><path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z"/></svg>
		</div>
	</div>

	<!-- Download Button -->
	<div v-if="file !== null" class="w-full flex justify-center my-5 px-5">
		<button v-on:click="onDownloadPdf" ref="button-download" class="overflow-hidden relative px-11 ring-12 ring-white dark:(ring-gray-700) w-auto py-4 text-center bg-gradient-to-r from-teal-100 to-yellow-100 text-gray-700 dark:(bg-none bg-gray-600 text-blue-300) rounded border-none font-playful font-bold text-xl shadow-sm disabled:(text-gray-500 cursor-wait) dark:disabled:(text-gray-800) hover:(shadow-inner)" :disabled="isGeneratingPdf">
			<div ref="download-progress" class="h-full bg-blue-300 opacity-50 absolute top-0 left-0 z-0 hover:(shadow-inner)"></div>
			<svg v-if="isGeneratingPdf" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="relative z-10 bi bi-gear inline animate-spin" viewBox="0 0 16 16"><path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z"/><path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z"/></svg>
			
			<span v-if="!isGeneratingPdf" class="relative z-10">Download</span>
			<span v-else class="relative z-10"> Konvertiert...</span>
		</button>
	</div>

  </div>
</template>

<script>
// @ is an alias to /src
import DragDropArea from '@/components/DragDropArea.vue';
import { PDFDocument, StandardFonts, rgb, PageSizes } from '@/assets/js/pdflib.min.js'
import { get, set } from 'idb-keyval';

export default {
	name: 'Home',
	components: {
		DragDropArea,
	},
	data: function() {
		return {
			file: null,
			preview: null,
			availableFonts: [],
			isGeneratingPdf: false,
			pagenum_pos: 'bottom,left',
		};
	},
	created: function() {
		this.availableFonts = [];
		for (let font in StandardFonts) {
			this.availableFonts.push(font);
		}
	},
	computed: {
	},
	methods: {
		humanFileSize: function(size) {
			const i = Math.floor(Math.log(size) / Math.log(1024));
			return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
		},
		onUploadClick: function() {
			if (this.isUpdatingFileList) return;

			this.$refs['file-upload'].$el.click();
		},
		onFileUploaded: async function(file) {
			// Abort on unknown file
			const supportedTypes = ['application/pdf'];
			if (!supportedTypes.includes(file.type)) {
				console.error('Not adding file ' + file.name + ' to list, its type ' + file.type + ' is not supported!');
				return;
			}

			if (['application/pdf'].includes(file.type)) {
				const bytes = await file.arrayBuffer();
				const doc = await PDFDocument.load(bytes);
				const pageCount = doc.getPageCount();
				
				this.file = {
					file: file,
					preview: null,
					pages: pageCount,
				};
			}
		},
		onBeforeFilesChanged: function(files) {
		},
		onAfterFilesChanged: function(files) {
		},
		onRemoveFile: function(file_to_remove) {
			this.file = null;
		},
		downloadPdfWebworker: function() {
			const worker = new Worker('/worker.js');
			worker.onmessage = (e) => {
				switch (e.data.type) {
					case 'pdf-done': {
						let a = document.createElement("a");
						a.href = e.data.pdfDataUri;
						a.download = "Seitennummern_" + this.file.file.name;
						a.click();
						//this.isGeneratingPdf = false;
						this.$refs['download-progress'].style.width = '0';
						break;
					};
					case 'pdf-progress': {
						this.$refs['download-progress'].style.width = (e.data.progress * 100) + '%';
						break;
					};
				};
			};

			let pagenums = [...document.querySelectorAll('.pagenum_index')]
				.map(el => el.value);

			//this.isGeneratingPdf = true;
			worker.postMessage({
				type: 'page-numbers',
				files: [this.file],
				pagenumbers: pagenums,
				color: this.$refs["fontcolor"].value,
				size: parseInt(this.$refs['fontsize'].value),
				margin: {
					x: parseInt(this.$refs["margin_x"].value),
					y: parseInt(this.$refs["margin_y"].value),
				},
				font: StandardFonts[this.$refs["fontfamily"].value],
				alignment: {
					x: this.pagenum_pos.split(",")[1] === 'left' ? -1 : 1,
					y: this.pagenum_pos.split(",")[0] === 'top' ? 1 : -1,
				},
			});
		},
		onDownloadPdf: async function() {
			this.downloadPdfWebworker();
		},
	},
}
</script>

<style scoped>

#navbar__title {
  white-space: nowrap;
}

input[type=number] {
	-moz-appearance: textfield;
}
input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

</style>
